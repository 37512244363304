<template>
  <v-dialog v-model="dialog" width="400px">
    <v-card >
      <v-card-title 
        class="headline primary white--text"
      >Deletar</v-card-title>

      <v-card-text class="mt-4">Tem certeza que quer deletar
        {{ selectedQuickReply.name }}?
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text  @click="dialog = false">Cancelar
        </v-btn>

        <v-btn color="error" text 
          @click="deleteQuickReply(selectedQuickReply.id)">Deletar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    ...mapActions('rasaQuickReplyList', {
      deleteSelectedQuickReply: 'delete'
    }),
    open() {
      this.dialog = true;
    },
    deleteQuickReply(id) {
      this.deleteSelectedQuickReply(id).then(() => {
        this.dialog = false;
        this.$emit('reloadTable')
      })
    }
  },
  computed: {
    ...mapGetters("rasaQuickReplyList", {
      selectedQuickReply: "selected",
    }),
  },

}
</script>

<style>
</style>