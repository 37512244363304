<template>
    <v-dialog 
      v-model="disableDialog" 
      :width="width" 
      @click:outside="clickOutside()"
      >
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          {{title}}
        </v-card-title>

        <v-card-text class="mt-5" v-if="message">
          {{message}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="primary" 
            text 
            @click="cancel()"
            > Cancelar </v-btn>
          <v-btn 
            color="red" 
            text 
            @click="disable()"
            > {{status ? 'Desativar' : 'Ativar'}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
    },
    title: {
      type: String,
      default: 'Desativar item?', 
    },
    width: {
      type: String,
      default: '350',
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'dialog',
    event: 'state'
  },
  data() {
    return {
      disableDialog: false,
      status: true,
    }
  },
  create() {},
  mounted() {},
  methods: {
    cancel() {
      this.disableDialog = false;
      this.$emit("cancel")
    },
    disable() {
      this.$emit("disable")
    },
    clickOutside() {
      this.$emit("clickOutside")
    }
  },
  watch: {
    dialog: {
      deep: true,
      handler(newValue) {
        this.disableDialog = newValue
      }
    },
    disableDialog: {
      deep: true,
      handler(newValue) {
        this.$emit('state', newValue)
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>