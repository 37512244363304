<template>
  <v-dialog content-class="modal-view" v-model="dialog" max-width="60%">
    <v-card class="secondary-background">
      <v-form>
        <v-card-title>Editar</v-card-title>
        <v-card-text>
          <v-container>
            <div cols="12" sm="6" md="4">
              <v-text-field
                :rules="[rules.required]"
                v-model="selectedQuickReply.key"
                label="Chave *"
              >
              </v-text-field>
            </div>
            <div cols="12" sm="6" md="4">
              <v-text-field
                v-model="selectedQuickReply.name"
                label="Utilização *"
              ></v-text-field>
            </div>
            <div class="editing dropzone" cols="12" md="4">
              <span>Mensagens</span>
              <hr style="width: 96%; margin-left: 2%" />
              <draggable
                v-model="selectedQuickReply.replies"
                @start="dragging = true"
                @end="dragging = false"
              >
                <template
                  v-for="(replyField, index) in selectedQuickReply.replies"
                >
                  <v-hover v-slot="{ hover }" :key="index">
                    <div>
                      <div class="dropItem primary-background">
                        <div class="text-container">
                          <v-textarea
                            content-class="fields-input-messages"
                            row-height="1px"
                            auto-grow
                            rows="3"
                            style="margin-top: 0px !important"
                            class="d-flex align-center"
                            v-model="selectedQuickReply.replies[index]"
                            placeholder="Digite a mensagem"
                            :rules="[rules.required]"
                          >
                            <v-icon
                              class="align-self-center"
                              :style="hover ? 'opacity: 1' : 'opacity: 0'"
                              slot="append"
                              color="red"
                              @click="removeField(index)"
                              >close</v-icon
                            >
                          </v-textarea>
                        </div>
                        <div class="icon-container">
                          <v-icon
                            class="draggable"
                            slot="prepend"
                            draggable="true"
                            style="cursor: pointer"
                            >drag_handle</v-icon
                          >
                        </div>
                      </div>
                      <div>
                        <hr style="width: 96%; margin-left: 2%" />
                      </div>
                    </div>
                  </v-hover>
                </template>

                <div class="addMessageContainer">
                  <v-btn @click="newMessage()" text small>ADICIONAR</v-btn>
                </div>
              </draggable>
            </div>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-checkbox style="justify-content: flex-end" label="Ativo"
            v-model="selectedQuickReply.active"></v-checkbox> -->
          <v-btn text @click="dialog = false">Cancelar </v-btn>
          <v-btn color="primary" text @click="save(selectedQuickReply)">
            Salvar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import draggable from "vuedraggable";
import lodash from "lodash";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      dialog: false,
      valid: true,
      isValid: false,
      selectedQuickReply: {
        service_id: this.$route.params.service_id
      },
      dragging: false,
      rules: {
        required: (value) => !!value || "Campo obrigatorio",
        validKey: (value) =>
          this.verifyIfKeyIsValid(value) || "Chave invalida ou ja em uso",
      },
    };
  },
  methods: {
    ...mapActions("rasaQuickReplyList", {
      saveSelectedQuickReply: "save",
      getQuickReply: "get",
      validateKey: "validateKey",
    }),
    ...mapMutations("rasaQuickReplyList", {
      setSelectedQuickReply: "setSelected",
    }),
    open() {
      this.valid = false;
      if (this.selected.id) {
        this.selectedQuickReply = JSON.parse(JSON.stringify(this.selected));
      } else {
        this.selectedQuickReply = {
          key: "",
          name: "",
          replies: [],
        };
      }

      this.dialog = true;
    },
    save(item) {
      item.service_id = this.$route.params.service_id;
      this.saveSelectedQuickReply(item)
        .then(() => {
          this.dialog = false;
          this.$emit("reloadTable");
        })
        .catch((err) => {});
    },
    newMessage() {
      this.selectedQuickReply.replies.push("");
    },
    removeField(index) {
      this.selectedQuickReply.replies.splice(index, 1);
    },
    verifyIfKeyIsValid: lodash.debounce(async function (key) {
      await this.validateKey(key).then((res) => {
        this.isValid = res.key;
      });
      return this.isValid;
    }, 600),
  },
  computed: {
    ...mapGetters("rasaQuickReplyList", {
      selected: "selected",
      quickRepliesList: "list",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/quickReply.scss";
.v-input__slot{
  align-items: center !important;
}
</style>