import QuickRepliesService from "@/services/quickReply";

export default {
  methods: {
    colorCode: function (buttonType) {
      if (buttonType == "WHATSAPP") return "#4caf50";
      if (buttonType == "SMS") return "#2196f3";
    },
    displayModalMessages: function (item) {
      this.repliesListPreview = item.replies;
      this.repliesListPreviewHeader = item.key;
      this.previewDialog = true;
    },
    messageDisplay: function (repliesList) {
      let content =
        repliesList.length > 1
          ? `${repliesList.length} mensagens`
          : repliesList.toString();
      return content;
    },
  },
}