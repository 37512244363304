<template>
  <v-dialog
    content-class="modal-view"
    scrollable
    v-model="dialog"
    max-width="40%"
  >
    <v-card class="secondary-background">
      <v-card-text>
        <v-layout
          align-center
          style="max-height: 400px"
          class="messages-container scroll-y"
          wrap
        >
          <div
            class="message sent"
            v-for="reply in selectedQuickReply.replies"
            :key="reply"
          >
            <div>
              <div class="text-container">
                <span style="margin: 5px">{{ reply }}</span>
              </div>
              <div class="image-container">
                <img
                  ng-src="https://s3-sa-east-1.amazonaws.com/gorillascode-auth/apps/2.png"
                  title="Logo"
                  src="https://s3-sa-east-1.amazonaws.com/gorillascode-auth/apps/2.png"
                  style="width: 100%"
                />
              </div>
            </div>
            <div class="time-container">
              <span>há poucos segundos</span>
            </div>
          </div>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="dialog = false"
          >Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
  },
  computed: {
    ...mapGetters("rasaQuickReplyList", {
      selectedQuickReply: "selected",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/quickReply.scss";
</style>