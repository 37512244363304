
<template>
  <v-layout column class="app">
    <!-- <top-bar /> -->
    <TopToolBar :back_action="'/customerService2/channel/list'"
      :back_title="currentService.name" :back_type="currentService.type"
      :selected_view="1" :isSearchable="true"
      :searchFunction="searchQuickReplies" :isFilterable="false">
      <template v-slot:deactivator>
        <v-layout class="align-center justify-center mt-6 mr-5">
          <v-container class="d-flex flex-row-reverse align-center" fluid>
            <v-switch v-model="activeSwitch" label="Mostrar Inativos">
            </v-switch>
          </v-container>
        </v-layout>
      </template>
    </TopToolBar>
    <quick-reply-tab ref="quickReplyTable" @editItem="editItem"
      @deleteItem="deleteItem" @previewItem="previewItem"
      @reloadTable="reloadTable" />
    <preview-dialog ref="previewDialog" />
    <edit-dialog ref="editDialog" @reloadTable="reloadTable" />
    <delete-dialog ref="deleteDialog" @reloadTable="reloadTable" />
    <v-btn style="bottom: 10px" absolute dark fab bottom right
      color="teal lighten-1" @click="editItem('new')">
      <v-icon>add</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import TopToolBar from "../components/TopToolBar";
import PreviewDialog from "./components/PreviewDialog.vue";
import EditDialog from "./components/EditDialog.vue";
import DeleteDialog from "./components/DeleteDialog.vue";
import QuickReplyTab from "./components/QuickReplyTab.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    TopToolBar,
    PreviewDialog,
    EditDialog,
    QuickReplyTab,
    DeleteDialog,
  },

  data() {
    return {
    }
  },
  methods: {
    ...mapMutations("rasaQuickReplyList", {
      setSelectedQuickReply: "setSelected",
      setActiveSwitch: 'setActiveSwitch'
    }),
    searchQuickReplies(search) {
      // this.$refs.quickReplyTable.delaySearchQuickReplies()s
      this.$refs.quickReplyTable.delaySearchQuickReplies(search);
    },
    editItem(type) {
      if (type == "new") {
        this.setSelectedQuickReply({});
      }
      this.$refs.editDialog.open();
    },
    deleteItem() {
      this.$refs.deleteDialog.open();
    },
    previewItem() {
      this.$refs.previewDialog.open();
    },
    reloadTable() {
      this.$refs.quickReplyTable.getQuickReply();
    },
  },
  computed: {
    ...mapGetters("rasaQuickReplyList", {
      selectedQuickReply: "selected",
      activeSwitchFromStore: 'activeSwitch'
    }),
    ...mapGetters("rasaConversation", {
      currentConversation: "currentConversation",
    }),
    ...mapGetters("rasaServices", {
      currentService: "currentService",
      channels: "services",
    }),
    activeSwitch: {
      get(){ return this.activeSwitchFromStore},
      set(){this.setActiveSwitch()}
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/quickReply.scss";
</style>