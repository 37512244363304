<template>
  <div class="quick-reply-list">
    <v-data-table class="quick-reply-data-table secondary-background" :headers="headers" fixed-header
      :items="quickRepliesListFiltered()" :item-key="'id'" :loading="quickRepliesLoading"
      :options.sync="options" :server-items-length="totalQuickReplies"
      :footer-props="{
        'items-per-page-options': [15, 50, 100],
        'items-per-page-text': 'Resultados por página',
      }">
      >
      <template v-slot:loading>
        <v-skeleton-loader type="table-row-divider@25"></v-skeleton-loader>
      </template>
      <template v-slot:item="props">
        <tr :class="props.item.active ? null : ' grey--text'">
          <td>{{ props.item.key }}</td>
          <td>{{ props.item.name }}</td>
          <td @click="previewItem(props.item)" style="cursor: pointer">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{
                  messageDisplay(props.item.replies)
                }}</span>
              </template>
              <span>Clique para visualizar</span>
            </v-tooltip>
          </td>
          <!-- <td class="text-center">
            <div class="active-circle"
              :class="{ 'is-active': props.item.active }"></div>
          </td> -->
          <td class="text-right">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">more_vert</v-icon>
              </template>
              <v-list class="v-list-context-menu tertiary-background">
                <v-list-item @click="editItem(props.item)" class="v-list-item">
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>
                <v-list-item @click="callDisableDialog(props.item)"
                  class="v-list-item">
                  <v-list-item-title>
                    {{props.item.active ? 'Desativar' : 'Ativar'}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteItem(props.item)"
                  class="v-list-item">
                  <v-list-item-title>Remover</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-alert :value="true" style="color: black" color="#ccc" icon="warning">
          Nenhum resultado encontrado</v-alert>
      </template>
    </v-data-table>
    <DisableDialog :message="disableMessage" :title="disableTittle"
      ref="disableDialog" @disable="changeQuickReplyStatus()" />
  </div>
</template>

<script>
import quickReplyCommon from "../mixins/quickReplyCommon.js";
import DisableDialog from "/src/components/Common/Dialogs/Disable.vue"

import { mapGetters, mapActions, mapMutations } from "vuex";
import lodash from "lodash";

export default {
  mixins: [quickReplyCommon],
  components: {
    DisableDialog,
  },
  data() {
    return {
      // activeSwitch: false,
      disableMessage: '',
      disableTittle: '',
      quickrepliesList: [],
      loadingFail: false,
      isMounted: false,
      options: {},
      headers: [
        {
          text: "Chave",
          align: "left",
          sortable: true,
          value: "key",
          width: "10%",
        },
        {
          text: "Utilização",
          align: "left",
          sortable: true,
          value: "name",
          width: "15%",
        },
        {
          text: "Mensagem",
          align: "left",
          sortable: true,
          value: "replies",
          width: "40%",
        },
        // {
        //   text: "Criado há",
        //   align: "left",
        //   sortable: true,
        //   value: "created_at",
        //   width: "15%",
        // },
        // {
        //   text: "Atualizado há",
        //   align: "left",
        //   sortable: true,
        //   value: "updated_at",
        //   width: "15%",
        // },
        // {
        //   text: "Ativa",
        //   align: "center",
        //   sortable: false,
        //   width: "2%",
        // },
        {
          text: "",
          align: "right",
          sortable: false,
          width: "1%",
        },
      ],
      tabTypesReverse: [],
      search: null,
    };
  },
  async mounted() {
    this.transformQueryParams(this.$route.query);
    this.getQuickReply();
    this.isMounted = true;
  },
  methods: {
    ...mapActions("rasaQuickReplyList", {
      getQuickReplies: "getAll",
      saveSelectedQuickReply: 'save',
    }),
    ...mapMutations("rasaQuickReplyList", {
      setSelectedQuickReply: "setSelected",
    }),
    delaySearchQuickReplies: lodash.debounce(function (search) {
      this.search = search;
      this.getQuickReply();
    }, 600),
    quickRepliesListFiltered(){
      return this.quickRepliesList.filter(el => el.service_id == this.$route.params.service_id)
    },
    async getQuickReply() {
      const { page, itemsPerPage } = this.options;
      let params = {
        limit: 999,
        order: this.createOrderParam(),
        search: this.search,
        page: page,
        active: !this.activeSwitch,
        service_id: this.currentService.id || this.$router.app._route.params.service_id
      };
      this.getQuickReplies(params).then(() => {
        this.$router
          .push({ path: "", query: this.makeQueryParams() })
          .catch(() => { });
      });
    },

    makeQueryParams() {
      return {
        page: this.options.page,
        order: this.options.sortBy[0],
        orderDesc: this.options.sortDesc[0],
        itemsPerPage: this.options.itemsPerPage,
        search: this.search ? this.search : undefined,
      };
    },
    transformQueryParams(query) {
      query.page ? (this.options.page = parseInt(query.page)) : 1;
      query.itemsPerPage
        ? (this.options.itemsPerPage = parseInt(query.itemsPerPage))
        : 15;
      query.order ? (this.options.sortBy = [query.order]) : null;
      query.orderDesc ? (this.options.sortDesc = [query.orderDesc]) : null;
      query.search ? (this.search = query.search) : null;
    },
    createOrderParam() {
      return this.options.sortDesc[0]
        ? "-" + this.options.sortBy[0]
        : this.options.sortBy[0];
    },
    editItem(item) {
      this.setSelectedQuickReply(JSON.parse(JSON.stringify(item)));
      this.$emit("editItem", item);
    },
    deleteItem(item) {
      this.setSelectedQuickReply(JSON.parse(JSON.stringify(item)));
      this.$emit("deleteItem");
    },
    previewItem(item) {
      this.setSelectedQuickReply(JSON.parse(JSON.stringify(item)));
      this.$emit("previewItem");
    },
    callDisableDialog(item) {
      this.setSelectedQuickReply(item)
      this.disableTittle = `${item.active ? 'Desativar' : 'Ativar'} quick reply`
      this.disableMessage = `Deseja ${item.active ? 'desativar' : 'ativar'} a quick reply ${this.selectedQuickReply.name}?`
      this.$refs.disableDialog.status = item.active
      this.$refs.disableDialog.disableDialog = true
    },
    changeQuickReplyStatus() {
      let params = {
        id: this.selectedQuickReply.id,
        active: !this.selectedQuickReply.active
      }
      this.saveSelectedQuickReply(params).then(() => {
        this.$refs.disableDialog.disableDialog = false
        this.$emit('reloadTable')
      })
    },    
  },
  computed: {
    ...mapGetters("rasaQuickReply", {
      activeSwitch: "activeSwitch",
    }),
    ...mapGetters("rasaQuickReplyList", {
      totalQuickReplies: "total",
      quickRepliesList: "list",
      quickRepliesLoading: "loading",
      selectedQuickReply: 'selected',
      activeSwitch: 'activeSwitch',
    }),
    ...mapGetters("rasaServices", {
      currentService: "currentService",
    }),
  },
  watch: {
    async activeSwitch() {
      if (this.isMounted) await this.getQuickReply();
    },
  },
};
</script>

<style lang="scss">
.active-circle {
  width: 18px;
  height: 18px;
  opacity: 0.8;
  border-radius: 50%;
  margin-left: 5px;
  background-color: #f44336;
  &.is-active {
    background-color: #4caf50;
  }
}
</style>